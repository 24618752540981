import React from 'react';
import Meta from 'meta';
import PropTypes from 'prop-types';
import {
  Allaffiliate,
} from 'screens';

const AffiliatePage = (props) => {
  const { location } = props;

  return (
    <>
      <Meta
        title="ALLTOP AFFILIATE"
        description="ตัวแทนหุ้นลมมาแต่ตัวก็รวยได้"
        pathname={location.pathname}
      />
      <Allaffiliate />
    </>
  );
};

AffiliatePage.propTypes = {
  location: PropTypes.string.isRequired,
};

export default AffiliatePage;
